module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Air Dining","short_name":"Air Dining","theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","icon":"./src/images/favicon.png","icons":[{"src":"/favicons/favicon.ico","sizes":"48x48","type":"image/x-icon"},{"src":"/favicons/16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f1f8c129fb34b6181a3c28fa18a1750"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
