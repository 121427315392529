import './src/styles.css'
export { wrapPageElement } from './src/wrapPageElement'
require('fontsource-inter')
require('fontsource-inter/700.css')

const verifyEnvironmentVariables = () => {
  if (!process.env.GATSBY_API_URL) {
    throw new Error(
      `GATSBY_API_URL is missing in the environment variables. Please add it.`
    )
  }
}

verifyEnvironmentVariables()
