import getStore from './store'
const React = require('react')
const { ThemeProvider } = require('@material-ui/core')
const { Provider } = require('react-redux')
const theme = require('./theme')

export const wrapPageElement = ({ element }) => {
  const store = getStore()
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </Provider>
  )
}
