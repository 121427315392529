const { createMuiTheme } = require('@material-ui/core')

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  palette: {
    primary: {
      main: '#f37626',
      light: '#fffdfc',
      contrastText: 'white',
    },
    text: {
      main: '#546285',
    },
  },
})

theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    fontSize: '3.125rem',
    color: '#08090a',
    letterSpacing: '-0.03em',
    fontWeight: 700,
    margin: '1.25rem 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '3.75rem',
    },
  },
  h2: {
    ...theme.typography.h2,
    fontSize: '3rem',
    margin: '1.25rem 0',
    fontWeight: 700,
    color: theme.palette.primary.main,
    lineHeight: 1,
  },
  h5: {
    ...theme.typography.h5,
    color: '#08090a',
    fontSize: '1.375rem',
    margin: '0.625rem 0',
    fontWeight: 700,
  },
  button: {
    ...theme.typography.button,
    fontSize: '1.125rem',
    textTransform: 'none',
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.main,
    fontSize: '1.125rem',
    lineHeight: 1.34,
    letterSpacing: '-.02em',
    fontWeight: 400,
  },
  body2: {
    ...theme.typography.body2,
    color: theme.palette.text.main,
    fontSize: '1.125rem',
    lineHeight: 1.5,
    letterSpacing: '-.02em',
    fontWeight: 400,
  },
}

module.exports = theme
